/*This part is overriding material ui component*/
.MuiTouchRipple-root-137 {
    display: none !important;
}

/* When there is only a single product, in .sidebar__root, */
/* there is also another class called .sidebar__root--single-product (for distinguishing) */

.sidebar {
    height: 100%;
    overflow: scroll;
    padding-bottom: 20px;
    z-index: 1;
}

.sidebar__root.pseudo {
    background-color: white;
    height: 100%;
    overflow: scroll;
}

.sidebar__paper.pseudo {
    background-color: rgba(220,231,245, 0.2);
    border-right: 1px solid #E3E8F6;
    height: 100%;
    position: relative;
    overflow: scroll;
}

.sidebar::-webkit-scrollbar {
    width: 0 !important;
}

.sidebar__root::-webkit-scrollbar {
    width: 0 !important;
}

.sidebar__paper::-webkit-scrollbar {
    width: 0 !important;
}

.arrow-icon {
    padding-left: 3px;
    padding-right: 3px;
}

.arrow-icon path:first-child {
    stroke: #61617B;
}

.product__container.pseudo {
    height: 50px;
    padding: 0 10px 0 0;
    transition: none;
    width: 280px;
}

.product__container.pseudo:hover {
    background-color: inherit;
}

.product__container.pseudo:focus {
    background-color: inherit;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .product__container.pseudo {
    display: none;
}

.product__colorbar {
    height: 100%;
    width: 3px;
}

.product__colorbar--open {
    background: #1B92F5;
}

.product__label.pseudo {
    font-size: 14px !important;
    color: #202325;
    line-height: 14px;
}

.product__label--open.pseudo {
    color: #1B92F5;
}

.product__icon-container {
    height: 24px;
}

.product__icon-container--open {
    background-color: #E4ECF7;
    border-radius: 12px;
}

.product__icon-container--hover {
    background-color: #E4ECF7;
    border-radius: 12px;
}

.metrics {
    background: rgba(220,231,245, 0.2);
}

.sidebar__root--single-product .metrics {
    background: transparent;
}

.metrics__padding {
    height: 14px;
    opacity: 0.6;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metrics__padding {
    display: none;
}

.metrics__padding-top {
    background-image: linear-gradient(to top, rgba(228,236,247, 0), rgba(228,236,247, 1));
}

.metrics__padding-bottom {
    margin-top: 2px;
    background-image: linear-gradient(to bottom, rgba(228,236,247, 0), rgba(228,236,247, 1));
}

.metric-group-title.pseudo {
    padding: 2px 20px 10px 40px;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metric-group-title.pseudo {
    padding-left: 20px;
    padding-top: 14px;
}

.metric + .metric-group-title {
    padding-top: 10px
}

.metric-group-title__text.pseudo {
    color: #202325;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.3;
    white-space: nowrap;
}

.metric-group-title__line {
    background-color: #E3E8F6;
    height: 1px;
    margin-left: 10px;
    margin-top: 5px;
    overflow: hidden;
    width: 100%;
}

.metric.pseudo {
    padding: 12px 0;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metric.pseudo {
    padding: 0;
}

.metric--selected.pseudo {
    background-color: #E4ECF7;
}

.metric--selected.pseudo:hover {
    background-color: #E4ECF7;
}

.metric--unselected.pseudo:hover {
    background-color: rgb(236, 241 , 248);
}

.metric__label-box.pseudo {
    padding: 1px 16px 1px 40px;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metric__label-box.pseudo {
    height: 100%;
    padding: 18px 20px;
}

.metric__label.pseudo {
    color: #202325;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
}

.metric__label--selected.pseudo {
    color: #1B92F5;
}

.metric__colorbar {
    height: 20px;
    width: 3px;
}

.metric__colorbar--selected {
    background: #1B92F5;
}

@media (min-width: 700px) {
    .sidebar__paper.pseudo {
        width: 280px;
    }

    .sidebar {
        position: fixed;
    }
}
