
.font--bold {
    font-family: "Open Sans", "sans-serif" !important;
    font-weight: 700 !important;
}

.font--semi-bold {
    font-family: "Open Sans", "sans-serif" !important;
    font-weight: 600 !important;
}

.font--normal {
    font-family: "Open Sans", "sans-serif" !important;
    font-weight: 400 !important;
}

.site {
    margin-top: 68px;
    width: calc(100% - 12px);
}

.main-component {
    flex-grow: 1;
}

@media (min-width: 700px) {
    .main-component {
        margin-left: 310px !important;
    }

    .site {
        width: calc(100% - 24px);
    }

}

.paper {
    display: inline-block;
}

.loading {
    height: 100%;
    margin-left: 10px;
    margin-top: 28px;
}

.loading .loadingSymbol {
    color: #1B92F5;
}
