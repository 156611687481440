body {
    margin: 0;
    margin-top: -8px !important;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.datepicker.pseudo {
    margin: 0 7px;
    padding: 0 !important;
    width: 100%;
}

@media (min-width: 700px) {
    .datepicker.pseudo {
        width: 200px;
    }
}

.datepicker__line {
    background: #202325;
    border-radius: 0.5px;
    height: 1px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 43px;
    width: 12px;
}

.report-button.pseudo {
    background: #FFFFFF;
    border: 1px solid #E3E8F6;
    border-radius: 2px;
    box-shadow: none;
    height: 36px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 26px;
    padding-left: 22px;
    padding-right: 21px;
    width: 90px;
}

.report-button.pseudo:hover {
    background: #EEF3FA;
    border: 1px solid #E3E8F6;
    border-radius: 2px;
}

.report-button__label {
    color: #1B92F5;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
}

.region-chips__select-country {
    font-size: 12px;
    color: #202325;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 10px;
}

.region-chip.region-chip--unselected {
    background: #FFFFFF;
    border: 1px solid #E3E8F6;
    border-radius: 14px;
    color: #1B92F5;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
}

.region-chip.region-chip--unselected:hover {
    background: #EEF3FA !important;
}

.region-chip.region-chip--unselected:focus:hover {
    background: #EEF3FA !important;
}

.region-chip.region-chip--unselected:focus {
    background-color: #FFFFFF !important;
}

.region-chip.region-chip--selected {
    border: 1px solid #1B92F5;
    background: #1B92F5;
    border-radius: 14px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
}

.region-chip.region-chip--selected:hover {
    background: #047DE2 !important;
}

.region-chip.region-chip--selected:focus:hover {
    background: #047DE2 !important;
}

.region-chip.region-chip--selected:focus {
    background-color: #1B92F5 !important;
}

.region-chip.pseudo {
    height: 28px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 60px;
}

.region-chips__divider {
    background: #E3E8F6;
    display: inline-block;
    margin: 0 16px -6px 6px;
    height: 24px;
    width: 1px;
}

.region-chips__modify-all.modify-all {
    height: 28px;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.modify-all__label {
    font-size: 14px;
    color: #1B92F5;
    text-align: center;
    line-height: 12px;
}

.modify-all + .modify-all {
    margin-left: 8px;
}

.radio-categories .radio-categories__radio-group {
    display: block;
}

.radio-categories .radio-categories__title {
    font-size: 12px;
    color: #202325;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 15px;
}

.radio__button {
    height: 25px;
    margin-bottom: 16px;
    margin-top: -10px;
}

.radio-categories .radio__button {
    margin-left: 0;
}

/*Dummy element to buffer the label width, to make sure the width does not change when bolded*/
.radio-categories .radio__label:after {
    content: attr(title);
    display: block;
    font-size: 14px;
    font-weight: 800;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.radio-categories .radio__button span:first-child {
    padding: 2px;
}

/*In order to enable different color for the border and the content*/
/*This is the color of the border*/
.radio-categories .radio__button svg {
    width: 20px;
    color: #E3E8F6;
}

.radio__button .radio__label {
    font-size: 14px;
}

/*Color of the label and ripple*/
.radio__button--selected span {
    color: #1B92F5;
}

/*This is the color of the content*/
.radio-categories .radio__button--selected > span:first-child:after {
    background-color: #1B92F5;
    border-radius: 100px;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    width: 8px;
}

.radio__button--selected .radio__label {
    font-weight: 600 !important;
}

.report__container {
    display: inline-block;
    margin-bottom: 40px;
    margin-right: 44px;
    /* 310 (sidebar) + 44 (this margin-right) + 24 (site gap to the right) */
    min-width: calc(100vw - 378px);
    overflow: hidden;
}

.report__title.pseudo {
    color: #202325;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 40px;
}

.report__back-link.parent-link {
    border-radius: 2px;
    color: #1B92F5;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 16px;
    line-height: normal;
    letter-spacing: normal;
    margin-top: -3px;
    margin-right: 10px;
    padding: 0;
    text-align: center;
    width: 50px;
    text-transform: capitalize;
}

.report__back-link.parent-link:hover {
    background-color: white;
}

.parent-link__back-arrow {
    margin-right: 10px;
    transform: scaleY(1.8);
}

.report__separator.separator.pseudo {
    background: #E3E8F6;
    height: 1px;
    margin-top: 24px;
    padding: 0;
}

.report__settings-container.settings-container.pseudo {
    background: rgba(220,231,245,0.20);
    border: 1px solid #E3E8F6;
    border-radius: 2px;
    margin: 20px 0 0 0;
    padding: 20px 20px 15px 20px;
}

.settings-container__datepicker-container.datepicker-container {
    display: flex;
    margin: 0 0 0 -7px;
}

.announcements-container {
    background-color: rgba(224, 184, 5, 0.2);
    margin-top: 20px;
    border-radius: 2px;
}

.announcement-typography {
    font-size: 14px;
    color: #521c04;
}

.realtime-report__margin {
    margin-top: -8px;
}

.realtime-report__body {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.realtime-report__body .chart-container__paper {
    border: 1px solid #E3E8F6;
    border-radius: 2px;
    box-shadow: none;
    position: relative;
}

.chart-container__paper .paper__metric-breakdowns {
    position: absolute;
    padding: 0 8px;
    right: 16px;
    text-align: center;
    top: 10px;
}

.paper__metric-breakdowns .metric-breakdowns__link {
    border-radius: 2px;
    color: #202325;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 28px;
    line-height: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    width: 150px;
}


.metric-breakdowns__link:hover {
    color: #1B92F5;
}

.chart {
    height: 315px;
}

.report__container .chart-container {
    margin-top: 40px;
    width: 100%;
}

.chart-container__paper--highlighted {
    background-color: rgba(237, 218, 130, 0.1) !important;
}

@media only screen and (min-width: 1280px) {
    .report__container .chart-container {
        width: calc(50% - 20px);
    }
}

.daily-report__table.table {
    margin-top: 32px;
}

.table__paper.paper {
    box-shadow: none;
    border: 1px solid #E3E8F6;
}

.table__header.header {
    background: rgba(220, 231, 245, 0.20);
}

.header__row:nth-child(2) {
    background: rgba(220, 231, 245, 0.20);
}

.table .table__cell {
    border-bottom: none;
    color: #202325;
    font-size: 14px;
    line-height: 14px;
    min-height: 50px;
    min-width: 110px;
    padding: 0px 10px;
}

.table .table__cell.cell__padded {
    padding: 17px 10px;
}

.table .table__cell.table__cell--small {
    min-width: 100px;
}

.header .header__table-region {
    background: #FFFFFF;
    border-left: none;
    font-size: 16px;
    line-height: 16px;
}

.header .header__row {
    border: none;
    height: 50px;
}

.header .header__row--small {
    height: 40px;
}

.header .header__children-link {
    border-radius: 2px;
    position: absolute;
    height: 16px;
    min-width: 16px;
}

.header .header__children-link--telco:hover:before {
    content: "Telco ";
    padding-right: 2px;
}

.header .header__children-link--loan-type:hover:before {
    content: "Types ";
    padding-right: 2px;
}

.header .header__link-group {
    border-radius: 2px;
    min-width: 16px;
}

.header__link-group:hover .link-group__arrow {
    display: none;
}

.header__link-group .link-group__arrow {
    display: block;
    margin-left: 5px;
    margin-top: 16px;
    position: absolute;
}

.header__link-group .arrow {
    transform: scale(0.7, 1.3);
}

.header__link-group:hover .link-group__link {
    display: block;
}

.header__link-group .link-group__link {
    display: none;
    margin-left: 5px;
    text-align: center;
    text-transform: capitalize;
    padding: 0;
}

.header__link-group .link-group__link:only-of-type {
    margin-top: 11px;
}

.header__link-group .link-group__link:hover {
    background-color: #eef3fa;
    color: #1b92f5;
}

.link-group__dropdown-menu {
    font-size: 14px !important;
}

.header .header__children-link:hover {
    background-color: #eef3fa;
    color: #1b92f5;
    height: 28px;
    margin-left: 8px;
    margin-top: -6px;
    text-transform: capitalize;
    width: 54px;
}

.header .header__row .header__cell .flex {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.children-link__arrow {
    transform: scale(0.7, 1.3);
}

.header__row--small .table__cell {
    padding: 12px 10px;
}

.table .table__row:nth-child(1) {
    border-top: 1px solid rgba(227, 232, 246, 1);
}

.table .table__row {
    border-top: 1px solid rgba(227, 232, 246, 0.5);
}

.table__cell--left-border {
    border-left: 1px solid #E3E8F6;
}

.moderate_healthy {
    color: #DE9000;
}

.at_risk {
    color: #F55045;
}

.table__cell .target {
    color: #20232570;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.datepicker p {
    display: none;
}


.font--bold {
    font-family: "Open Sans", "sans-serif" !important;
    font-weight: 700 !important;
}

.font--semi-bold {
    font-family: "Open Sans", "sans-serif" !important;
    font-weight: 600 !important;
}

.font--normal {
    font-family: "Open Sans", "sans-serif" !important;
    font-weight: 400 !important;
}

.site {
    margin-top: 68px;
    width: calc(100% - 12px);
}

.main-component {
    flex-grow: 1;
}

@media (min-width: 700px) {
    .main-component {
        margin-left: 310px !important;
    }

    .site {
        width: calc(100% - 24px);
    }

}

.paper {
    display: inline-block;
}

.loading {
    height: 100%;
    margin-left: 10px;
    margin-top: 28px;
}

.loading .loadingSymbol {
    color: #1B92F5;
}

/*This part is overriding material ui component*/
.MuiTouchRipple-root-137 {
    display: none !important;
}

/* When there is only a single product, in .sidebar__root, */
/* there is also another class called .sidebar__root--single-product (for distinguishing) */

.sidebar {
    height: 100%;
    overflow: scroll;
    padding-bottom: 20px;
    z-index: 1;
}

.sidebar__root.pseudo {
    background-color: white;
    height: 100%;
    overflow: scroll;
}

.sidebar__paper.pseudo {
    background-color: rgba(220,231,245, 0.2);
    border-right: 1px solid #E3E8F6;
    height: 100%;
    position: relative;
    overflow: scroll;
}

.sidebar::-webkit-scrollbar {
    width: 0 !important;
}

.sidebar__root::-webkit-scrollbar {
    width: 0 !important;
}

.sidebar__paper::-webkit-scrollbar {
    width: 0 !important;
}

.arrow-icon {
    padding-left: 3px;
    padding-right: 3px;
}

.arrow-icon path:first-child {
    stroke: #61617B;
}

.product__container.pseudo {
    height: 50px;
    padding: 0 10px 0 0;
    transition: none;
    width: 280px;
}

.product__container.pseudo:hover {
    background-color: inherit;
}

.product__container.pseudo:focus {
    background-color: inherit;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .product__container.pseudo {
    display: none;
}

.product__colorbar {
    height: 100%;
    width: 3px;
}

.product__colorbar--open {
    background: #1B92F5;
}

.product__label.pseudo {
    font-size: 14px !important;
    color: #202325;
    line-height: 14px;
}

.product__label--open.pseudo {
    color: #1B92F5;
}

.product__icon-container {
    height: 24px;
}

.product__icon-container--open {
    background-color: #E4ECF7;
    border-radius: 12px;
}

.product__icon-container--hover {
    background-color: #E4ECF7;
    border-radius: 12px;
}

.metrics {
    background: rgba(220,231,245, 0.2);
}

.sidebar__root--single-product .metrics {
    background: transparent;
}

.metrics__padding {
    height: 14px;
    opacity: 0.6;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metrics__padding {
    display: none;
}

.metrics__padding-top {
    background-image: linear-gradient(to top, rgba(228,236,247, 0), rgba(228,236,247, 1));
}

.metrics__padding-bottom {
    margin-top: 2px;
    background-image: linear-gradient(to bottom, rgba(228,236,247, 0), rgba(228,236,247, 1));
}

.metric-group-title.pseudo {
    padding: 2px 20px 10px 40px;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metric-group-title.pseudo {
    padding-left: 20px;
    padding-top: 14px;
}

.metric + .metric-group-title {
    padding-top: 10px
}

.metric-group-title__text.pseudo {
    color: #202325;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.3;
    white-space: nowrap;
}

.metric-group-title__line {
    background-color: #E3E8F6;
    height: 1px;
    margin-left: 10px;
    margin-top: 5px;
    overflow: hidden;
    width: 100%;
}

.metric.pseudo {
    padding: 12px 0;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metric.pseudo {
    padding: 0;
}

.metric--selected.pseudo {
    background-color: #E4ECF7;
}

.metric--selected.pseudo:hover {
    background-color: #E4ECF7;
}

.metric--unselected.pseudo:hover {
    background-color: rgb(236, 241 , 248);
}

.metric__label-box.pseudo {
    padding: 1px 16px 1px 40px;
}

/* For special case where there is only a single product */
.sidebar__root--single-product .metric__label-box.pseudo {
    height: 100%;
    padding: 18px 20px;
}

.metric__label.pseudo {
    color: #202325;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
}

.metric__label--selected.pseudo {
    color: #1B92F5;
}

.metric__colorbar {
    height: 20px;
    width: 3px;
}

.metric__colorbar--selected {
    background: #1B92F5;
}

@media (min-width: 700px) {
    .sidebar__paper.pseudo {
        width: 280px;
    }

    .sidebar {
        position: fixed;
    }
}

.header.pseudo {
    height: 60px;
    background-color: #43435E;
    box-shadow: none;
}

.toolbar.pseudo {
    height: 100%;
    min-height: 32px;
    padding-left: 20px;
}

.toolbar__logo {
    height: 32px;
    width: 48px;
}

.toolbar__title.pseudo {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    margin-left: 10px;
}

.profile.pseudo {
    border-radius: 22px;
    height: 44px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 0 5px 0 6px;
    position: absolute;
    right: 20px;
    top: 0;
}

.profile.pseudo:hover {
    color: #393953;
}

.profile__picture {
    border-radius: 17px;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,0.20);
    height: 34px;
    width: 34px;
}

.profile__name.pseudo {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 12px;
    margin-left: 8px;
    text-transform: none;
}

.menu.pseudo {
    margin: 0;
    padding: 0;
}

.menu__list.pseudo {
    margin: 0;
    padding: 0;
}

.menu__item.pseudo {
    background: #FFFFFF;
    border: 1px solid #F2F4F9;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(97,97,123,0.05);
    color: #1B92F5;
    font-size: 14px;
    height: 36px;
    line-height: 12px;
    padding: 0;
    text-align: center;
    width: 90px;
}

.menu__text {
    width: 100%;
}

