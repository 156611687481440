.daily-report__table.table {
    margin-top: 32px;
}

.table__paper.paper {
    box-shadow: none;
    border: 1px solid #E3E8F6;
}

.table__header.header {
    background: rgba(220, 231, 245, 0.20);
}

.header__row:nth-child(2) {
    background: rgba(220, 231, 245, 0.20);
}

.table .table__cell {
    border-bottom: none;
    color: #202325;
    font-size: 14px;
    line-height: 14px;
    min-height: 50px;
    min-width: 110px;
    padding: 0px 10px;
}

.table .table__cell.cell__padded {
    padding: 17px 10px;
}

.table .table__cell.table__cell--small {
    min-width: 100px;
}

.header .header__table-region {
    background: #FFFFFF;
    border-left: none;
    font-size: 16px;
    line-height: 16px;
}

.header .header__row {
    border: none;
    height: 50px;
}

.header .header__row--small {
    height: 40px;
}

.header .header__children-link {
    border-radius: 2px;
    position: absolute;
    height: 16px;
    min-width: 16px;
}

.header .header__children-link--telco:hover:before {
    content: "Telco ";
    padding-right: 2px;
}

.header .header__children-link--loan-type:hover:before {
    content: "Types ";
    padding-right: 2px;
}

.header .header__link-group {
    border-radius: 2px;
    min-width: 16px;
}

.header__link-group:hover .link-group__arrow {
    display: none;
}

.header__link-group .link-group__arrow {
    display: block;
    margin-left: 5px;
    margin-top: 16px;
    position: absolute;
}

.header__link-group .arrow {
    transform: scale(0.7, 1.3);
}

.header__link-group:hover .link-group__link {
    display: block;
}

.header__link-group .link-group__link {
    display: none;
    margin-left: 5px;
    text-align: center;
    text-transform: capitalize;
    padding: 0;
}

.header__link-group .link-group__link:only-of-type {
    margin-top: 11px;
}

.header__link-group .link-group__link:hover {
    background-color: #eef3fa;
    color: #1b92f5;
}

.link-group__dropdown-menu {
    font-size: 14px !important;
}

.header .header__children-link:hover {
    background-color: #eef3fa;
    color: #1b92f5;
    height: 28px;
    margin-left: 8px;
    margin-top: -6px;
    text-transform: capitalize;
    width: 54px;
}

.header .header__row .header__cell .flex {
    display: flex;
    align-items: center;
    gap: 4px;
}

.children-link__arrow {
    transform: scale(0.7, 1.3);
}

.header__row--small .table__cell {
    padding: 12px 10px;
}

.table .table__row:nth-child(1) {
    border-top: 1px solid rgba(227, 232, 246, 1);
}

.table .table__row {
    border-top: 1px solid rgba(227, 232, 246, 0.5);
}

.table__cell--left-border {
    border-left: 1px solid #E3E8F6;
}

.moderate_healthy {
    color: #DE9000;
}

.at_risk {
    color: #F55045;
}

.table__cell .target {
    color: #20232570;
}
