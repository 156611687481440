.header.pseudo {
    height: 60px;
    background-color: #43435E;
    box-shadow: none;
}

.toolbar.pseudo {
    height: 100%;
    min-height: 32px;
    padding-left: 20px;
}

.toolbar__logo {
    height: 32px;
    width: 48px;
}

.toolbar__title.pseudo {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    margin-left: 10px;
}

.profile.pseudo {
    border-radius: 22px;
    height: 44px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 0 5px 0 6px;
    position: absolute;
    right: 20px;
    top: 0;
}

.profile.pseudo:hover {
    color: #393953;
}

.profile__picture {
    border-radius: 17px;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,0.20);
    height: 34px;
    width: 34px;
}

.profile__name.pseudo {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 12px;
    margin-left: 8px;
    text-transform: none;
}

.menu.pseudo {
    margin: 0;
    padding: 0;
}

.menu__list.pseudo {
    margin: 0;
    padding: 0;
}

.menu__item.pseudo {
    background: #FFFFFF;
    border: 1px solid #F2F4F9;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(97,97,123,0.05);
    color: #1B92F5;
    font-size: 14px;
    height: 36px;
    line-height: 12px;
    padding: 0;
    text-align: center;
    width: 90px;
}

.menu__text {
    width: 100%;
}
