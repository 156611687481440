.realtime-report__margin {
    margin-top: -8px;
}

.realtime-report__body {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.realtime-report__body .chart-container__paper {
    border: 1px solid #E3E8F6;
    border-radius: 2px;
    box-shadow: none;
    position: relative;
}

.chart-container__paper .paper__metric-breakdowns {
    position: absolute;
    padding: 0 8px;
    right: 16px;
    text-align: center;
    top: 10px;
}

.paper__metric-breakdowns .metric-breakdowns__link {
    border-radius: 2px;
    color: #202325;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 28px;
    line-height: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    width: 150px;
}


.metric-breakdowns__link:hover {
    color: #1B92F5;
}

.chart {
    height: 315px;
}

.report__container .chart-container {
    margin-top: 40px;
    width: 100%;
}

.chart-container__paper--highlighted {
    background-color: rgba(237, 218, 130, 0.1) !important;
}

@media only screen and (min-width: 1280px) {
    .report__container .chart-container {
        width: calc(50% - 20px);
    }
}
