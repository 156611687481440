.region-chips__select-country {
    font-size: 12px;
    color: #202325;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 10px;
}

.region-chip.region-chip--unselected {
    background: #FFFFFF;
    border: 1px solid #E3E8F6;
    border-radius: 14px;
    color: #1B92F5;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
}

.region-chip.region-chip--unselected:hover {
    background: #EEF3FA !important;
}

.region-chip.region-chip--unselected:focus:hover {
    background: #EEF3FA !important;
}

.region-chip.region-chip--unselected:focus {
    background-color: #FFFFFF !important;
}

.region-chip.region-chip--selected {
    border: 1px solid #1B92F5;
    background: #1B92F5;
    border-radius: 14px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
}

.region-chip.region-chip--selected:hover {
    background: #047DE2 !important;
}

.region-chip.region-chip--selected:focus:hover {
    background: #047DE2 !important;
}

.region-chip.region-chip--selected:focus {
    background-color: #1B92F5 !important;
}

.region-chip.pseudo {
    height: 28px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 60px;
}

.region-chips__divider {
    background: #E3E8F6;
    display: inline-block;
    margin: 0 16px -6px 6px;
    height: 24px;
    width: 1px;
}

.region-chips__modify-all.modify-all {
    height: 28px;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.modify-all__label {
    font-size: 14px;
    color: #1B92F5;
    text-align: center;
    line-height: 12px;
}

.modify-all + .modify-all {
    margin-left: 8px;
}
