.radio-categories .radio-categories__radio-group {
    display: block;
}

.radio-categories .radio-categories__title {
    font-size: 12px;
    color: #202325;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 15px;
}

.radio__button {
    height: 25px;
    margin-bottom: 16px;
    margin-top: -10px;
}

.radio-categories .radio__button {
    margin-left: 0;
}

/*Dummy element to buffer the label width, to make sure the width does not change when bolded*/
.radio-categories .radio__label:after {
    content: attr(title);
    display: block;
    font-size: 14px;
    font-weight: 800;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.radio-categories .radio__button span:first-child {
    padding: 2px;
}

/*In order to enable different color for the border and the content*/
/*This is the color of the border*/
.radio-categories .radio__button svg {
    width: 20px;
    color: #E3E8F6;
}

.radio__button .radio__label {
    font-size: 14px;
}

/*Color of the label and ripple*/
.radio__button--selected span {
    color: #1B92F5;
}

/*This is the color of the content*/
.radio-categories .radio__button--selected > span:first-child:after {
    background-color: #1B92F5;
    border-radius: 100px;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    width: 8px;
}

.radio__button--selected .radio__label {
    font-weight: 600 !important;
}
