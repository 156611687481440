.report__container {
    display: inline-block;
    margin-bottom: 40px;
    margin-right: 44px;
    /* 310 (sidebar) + 44 (this margin-right) + 24 (site gap to the right) */
    min-width: calc(100vw - 378px);
    overflow: hidden;
}

.report__title.pseudo {
    color: #202325;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 40px;
}

.report__back-link.parent-link {
    border-radius: 2px;
    color: #1B92F5;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 16px;
    line-height: normal;
    letter-spacing: normal;
    margin-top: -3px;
    margin-right: 10px;
    padding: 0;
    text-align: center;
    width: 50px;
    text-transform: capitalize;
}

.report__back-link.parent-link:hover {
    background-color: white;
}

.parent-link__back-arrow {
    margin-right: 10px;
    transform: scaleY(1.8);
}

.report__separator.separator.pseudo {
    background: #E3E8F6;
    height: 1px;
    margin-top: 24px;
    padding: 0;
}

.report__settings-container.settings-container.pseudo {
    background: rgba(220,231,245,0.20);
    border: 1px solid #E3E8F6;
    border-radius: 2px;
    margin: 20px 0 0 0;
    padding: 20px 20px 15px 20px;
}

.settings-container__datepicker-container.datepicker-container {
    display: flex;
    margin: 0 0 0 -7px;
}

.announcements-container {
    background-color: rgba(224, 184, 5, 0.2);
    margin-top: 20px;
    border-radius: 2px;
}

.announcement-typography {
    font-size: 14px;
    color: #521c04;
}
