.datepicker.pseudo {
    margin: 0 7px;
    padding: 0 !important;
    width: 100%;
}

@media (min-width: 700px) {
    .datepicker.pseudo {
        width: 200px;
    }
}

.datepicker__line {
    background: #202325;
    border-radius: 0.5px;
    height: 1px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 43px;
    width: 12px;
}

.report-button.pseudo {
    background: #FFFFFF;
    border: 1px solid #E3E8F6;
    border-radius: 2px;
    box-shadow: none;
    height: 36px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 26px;
    padding-left: 22px;
    padding-right: 21px;
    width: 90px;
}

.report-button.pseudo:hover {
    background: #EEF3FA;
    border: 1px solid #E3E8F6;
    border-radius: 2px;
}

.report-button__label {
    color: #1B92F5;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
}
